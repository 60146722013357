import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/google-picker-with-drive",
  "date": "2014-09-04",
  "title": "GOOGLE PICKER WITH DRIVE",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Google Drive is a file storage service that enables users to organize, backup, and share photos, videos, and other documents in the cloud. And allows you to access your files across many different devices, including smartphones."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Google Picker with Drive`}</h2>
    <p>{`Replaces your traditional upload form. Google Picker is a modal window we can put in our application
that integrates with many of Google’s services, including Drive. It’s Easier Than `}<strong parentName="p">{`input type=”file”`}</strong>{`"`}</p>
    <p>{`Google Picker uploads files directly to
the user’s Drive, which means...`}</p>
    <ul>
      <li parentName="ul">{`It’s not taking up space on our server`}</li>
      <li parentName="ul">{`It’s not using our bandwidth`}</li>
      <li parentName="ul">{`We don’t have to write any upload code`}</li>
      <li parentName="ul">{`It also looks a lot nicer than the standard file input`}</li>
    </ul>
    <h2>{`Google Developers Console`}</h2>
    <p>{`To get started ..`}</p>
    <ul>
      <li parentName="ul">{`Create a New API Project`}</li>
      <li parentName="ul">{`Enable the Google Picker API`}</li>
      <li parentName="ul">{`Create a New OAuth Client ID`}</li>
      <li parentName="ul">{`Create a Developer API Key`}</li>
    </ul>
    <p>{`In our application..`}</p>
    <ul>
      <li parentName="ul">{`Include the Google API Loader script`}</li>
      <li parentName="ul">{`Authorize the User`}</li>
      <li parentName="ul">{`Render the Picker`}</li>
    </ul>
    <h2>{`Creating a New API Project`}</h2>
    <p>{`In the Google Developers Console, create a new API Project.`}</p>
    <h2>{`Enabling the Google Picker API`}</h2>
    <p>{`In the sidebar, goto APIs & auth > APIs then enable the Google Picker API.`}</p>
    <h2>{`Creating a New OAuth Client ID`}</h2>
    <p>{`Goto APIs & auth > Credentials then click CREATE NEW CLIENT ID.`}</p>
    <h2>{`Creating a API Key`}</h2>
    <p>{`Goto APIs & auth > Credentials then click CREATE NEW KEY and choose
Browser key.`}</p>
    <p>{`Enter your site’s URL. The page will now show our newly created API key!`}</p>
    <h2>{`The Google API Loader script`}</h2>
    <p>{`This script will be used to load Google’s Authentication and Picker APIs.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<body>
<script type="text/javascript" src="https://apis.google.com/js/api.js?onload=onApiLoad"></script>
</body>
`}</code></pre>
    <p>{`this method gets called when the API
Loader script is ready to use (we’ll define that method in a bit)`}</p>
    <h2>{`Loading The Picker API`}</h2>
    <p>{`Before we can create a Google Picker modal window, we need to first load the necessary API.
We've setup a script tag which will call our local onApiLoad function when complete.
Implement this function and have it load the auth and picker libraries. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script>
  function onApiLoad() {
    gapi.load('auth');
    gapi.load('picker');
  }
</script>
<script src="https://apis.google.com/js/api.js?onload=onApiLoad"></script>
`}</code></pre>
    <h2>{`Loading the Auth and Picker APIs`}</h2>
    <p>{`The method below gets called when the Auth
API is done loading (we’ll define that method in a bit too)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function onApiLoad() {
gapi.load('auth', {'callback': onAuthApiLoad});
gapi.load('picker');
}
`}</code></pre>
    <p>{`this tells Google we want access to the user’s Drive, called after the user authenticates (we’ll implement that method next)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function onAuthApiLoad() {
window.gapi.auth.authorize({
'client_id': 'XXXXXXXXXXXX',
'scope': ['https://www.googleapis.com/auth/drive']
}, handleAuthResult);
}
`}</code></pre>
    <h2>{`Authenticating the User`}</h2>
    <p>{`Save the oauthToken and create the Picker.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var oauthToken;
function handleAuthResult(authResult) {
    if (authResult && !authResult.error) {
        oauthToken = authResult.access_token;
        createPicker();
    }
}
`}</code></pre>
    <p>{`this is a method we’ll define next that will create our Picker`}</p>
    <h2>{`After Authentication`}</h2>
    <p>{`We're successfully loading the Google Auth and Google Picker libraries! After the auth library has loaded, we'll need to authorize with the Google API. To do this, create a new function named authenticateWithGoogle which will be run after the auth library has loaded.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script>
  function onApiLoad() {
    gapi.load('auth', {'callback': authenticateWithGoogle});
    gapi.load('picker');
  }
  function authenticateWithGoogle() {
  }
</script>
<script src="https://apis.google.com/js/api.js?onload=onApiLoad"></script>
`}</code></pre>
    <h2>{`Authenticate with Google`}</h2>
    <p>{`We can start the rest of the Google Authentication process now in our authenticateWithGoogle.
Implement this function to authorize the `}<a parentName="p" {...{
        "href": "https://www.googleapis.com/auth/drive"
      }}>{`https://www.googleapis.com/auth/drive`}</a>{` scope with a `}<inlineCode parentName="p">{`client_id`}</inlineCode>{` using the variable we defined. Don't worry about the callback on success yet, we'll do that next.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script>
  var clientId = '720409271749-hqv3lb4u0q82t68vperem5oi3kosskol.apps.googleusercontent.com';
  function onApiLoad() {
    gapi.load('auth', authenticateWithGoogle);
    gapi.load('picker');  
  }
  function authenticateWithGoogle() {
    window.gapi.auth.authorize({
      'client_id': clientId,
      'scope': ['https://www.googleapis.com/auth/drive']
    });
  }
</script>
<script src="https://apis.google.com/js/api.js?onload=onApiLoad"></script>
`}</code></pre>
    <h2>{`Handling Authentication`}</h2>
    <p>{`After we authenticate with Google with our API key, we can show our actual Picker! …assuming the authentication was successful.
Create a new handleAuthentication function that will be called after authentication.
In this function, save their `}<inlineCode parentName="p">{`access_token`}</inlineCode>{` to the accessToken variable if authentication was successful.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script>
  var clientId = '720409271749-hqv3lb4u0q82t68vperem5oi3kosskol.apps.googleusercontent.com';
  var accessToken;
  function onApiLoad() {
    gapi.load('auth', authenticateWithGoogle);
    gapi.load('picker');
  }
  function authenticateWithGoogle() {
    window.gapi.auth.authorize({
      'client_id': clientId,
      'scope': ['https://www.googleapis.com/auth/drive']},
      handleAuthentication);
  }
  function handleAuthentication(authResult) {
    if (authResult && !authResult.error) {
      accessToken = authResult.access_token;     
    }
  } 
</script>
<script src="https://apis.google.com/js/api.js?onload=onApiLoad"></script>
`}</code></pre>
    <h2>{`Basic Picker`}</h2>
    <p>{`After successfully authenticating is a perfect time to set up our picker! Create a new setupPicker function which is executed after successful authentication. In this function, build out a new picker using our saved accessToken and render it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script>
  var clientId = '720409271749-hqv3lb4u0q82t68vperem5oi3kosskol.apps.googleusercontent.com';
  var developerKey = 'AIzaSyBTEWsJ4aXdoOzB4ey81eX9-ja7HejL4Qc';
  var accessToken;
  function onApiLoad() {
    gapi.load('auth', authenticateWithGoogle);
    gapi.load('picker');
  }
  function authenticateWithGoogle() {
    window.gapi.auth.authorize({
      'client_id': clientId,
      'scope': ['https://www.googleapis.com/auth/drive']
    }, handleAuthentication);
  }
  function handleAuthentication(result) {
    if(result && !result.error) {
      accessToken = result.access_token;
      setupPicker();
    }
  }
  function setupPicker() {
    var picker = new google.picker.PickerBuilder()
    .setOAuthToken(accessToken)
    .setDeveloperKey(developerKey)
    .build();
    picker.setVisible(true);    
  }
</script>
<script src="https://apis.google.com/js/api.js?onload=onApiLoad"></script>
`}</code></pre>
    <h2>{`Adding Views`}</h2>
    <p>{`We've successfully rendered our Picker, but it's just an empty window. Let's add a new view to it so it does something.`}</p>
    <p>{`Update `}<inlineCode parentName="p">{`setupPicker()`}</inlineCode>{` to give our Picker the ability to upload files to Google Drive.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script>
  var clientId = '720409271749-hqv3lb4u0q82t68vperem5oi3kosskol.apps.googleusercontent.com';
  var developerKey = 'AIzaSyBTEWsJ4aXdoOzB4ey81eX9-ja7HejL4Qc';
  var accessToken;
  function onApiLoad() {
    gapi.load('auth', authenticateWithGoogle);
    gapi.load('picker');
  }
  function authenticateWithGoogle() {
    window.gapi.auth.authorize({
      'client_id': clientId,
      'scope': ['https://www.googleapis.com/auth/drive']
    }, handleAuthentication);
  }
  function handleAuthentication(result) {
    if(result && !result.error) {
      accessToken = result.access_token;
      setupPicker();
    }
  }
  function setupPicker() {
    var picker = new google.picker.PickerBuilder()
      .addView(new google.picker.DocsUploadView())
      .setOAuthToken(accessToken)
      .setDeveloperKey(developerKey)
      .build();
    picker.setVisible(true);
  }
</script>
<script src="https://apis.google.com/js/api.js?onload=onApiLoad"></script>
`}</code></pre>
    <p>{`Let's give the Picker the ability to list files that a user can select.`}</p>
    <p>{`Add the appropriate view to the Picker.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script>
  var clientId = '720409271749-hqv3lb4u0q82t68vperem5oi3kosskol.apps.googleusercontent.com';
  var developerKey = 'AIzaSyBTEWsJ4aXdoOzB4ey81eX9-ja7HejL4Qc';
  var accessToken;
  function onApiLoad() {
    gapi.load('auth', authenticateWithGoogle);
    gapi.load('picker');
  }
  function authenticateWithGoogle() {
    window.gapi.auth.authorize({
      'client_id': clientId,
      'scope': ['https://www.googleapis.com/auth/drive']
    }, handleAuthentication);
  }
  function handleAuthentication(result) {
    if(result && !result.error) {
      accessToken = result.access_token;
      setupPicker();
    }
  }
  function setupPicker() {
    var picker = new google.picker.PickerBuilder()
      .setOAuthToken(accessToken)
      .setDeveloperKey(developerKey)
      .addView(new google.picker.DocsUploadView())
      .addView(new google.picker.DocsView())
      .build();
    picker.setVisible(true);
  }
</script>
<script src="https://apis.google.com/js/api.js?onload=onApiLoad"></script>
`}</code></pre>
    <h2>{`Setting a Callback Function`}</h2>
    <p>{`We've given users the ability to view and select files with the DocsView, but clicking the select button doesn't do anything yet.`}</p>
    <p>{`Define a callback function called myCallback() and set that as the Picker callback.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script>
  var clientId = '720409271749-hqv3lb4u0q82t68vperem5oi3kosskol.apps.googleusercontent.com';
  var developerKey = 'AIzaSyBTEWsJ4aXdoOzB4ey81eX9-ja7HejL4Qc';
  var accessToken;
  function onApiLoad() {
    gapi.load('auth', authenticateWithGoogle);
    gapi.load('picker');
  }
  function authenticateWithGoogle() {
    window.gapi.auth.authorize({
      'client_id': clientId,
      'scope': ['https://www.googleapis.com/auth/drive']
    }, handleAuthentication);
  }
  function handleAuthentication(result) {
    if(result && !result.error) {
      accessToken = result.access_token;
      setupPicker();
    }
  }
  function setupPicker() {
    var picker = new google.picker.PickerBuilder()
      .setOAuthToken(accessToken)
      .setDeveloperKey(developerKey)
      .addView(new google.picker.DocsUploadView())
      .addView(new google.picker.DocsView())
      .setCallback(myCallback)
      .build();
    picker.setVisible(true);
  }
  function myCallback(data) {
    
  }
</script>
<script src="https://apis.google.com/js/api.js?onload=onApiLoad"></script>
`}</code></pre>
    <h2>{`Setting a Callback Function`}</h2>
    <p>{`We've given users the ability to view and select files with the DocsView, but clicking the select button doesn't do anything yet.`}</p>
    <p>{`Define a callback function called myCallback() and set that as the Picker callback.`}</p>
    <p><inlineCode parentName="p">{`js`}</inlineCode></p>
    <h2>{`Accessing File Metadata`}</h2>
    <p>{`What good is a callback function if it doesn't do anything?`}</p>
    <p>{`Update myCallback to alert the name of the first file passed in.`}</p>
    <p>{`[code 1="myCallback(data)" 2="{" 3="alert(data.docs`}{`[0" language="function"]`}{`.name);
}`}</p>
    <pre><code parentName="pre" {...{}}>{`
## Handling Picker Actions

The callback function gets triggered when an event occurs in the Picker, not just selecting files.

Update myCallback to only alert the file name if the user clicked Select in the Picker modal.

\`\`\`js
function myCallback(data) {
  if(data.action == google.picker.Action.PICKED) {
    alert(data.docs[0].name);
  }
}
`}</code></pre>
    <h2>{`Handling Picker Actions, Part 2`}</h2>
    <p>{`Remember, multiple events can trigger the callback function, so we need to react differently based on what event occurred.`}</p>
    <p>{`Update myCallback to alert goodbye when the user clicks the Cancel button.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function myCallback(data) {
    if (data.action == google.picker.Action.PICKED) {
      alert(data.docs[0].name);
    } else if (data.action == google.picker.Action.CANCEL) {
      alert('goodbye');
    }
  }
`}</code></pre>
    <h2>{`Enabling New Features`}</h2>
    <p>{`In this particular Picker example, we only have one view, so the navigation sidebar isn't necessary. Fortunately, there's a feature we can enable that hides the navigation.`}</p>
    <p>{`Update the Picker so that the navigation is hidden.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function setupPicker() {
  var picker = new google.picker.PickerBuilder()
    .setOAuthToken(accessToken)
    .setDeveloperKey(developerKey)
    .addView(new google.picker.DocsUploadView())
    .enableFeature(google.picker.Feature.NAV_HIDDEN)
    .setCallback(myCallback)
    .build();
  picker.setVisible(true);
}
`}</code></pre>
    <p>{`Final source code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script>
  var clientId = '720409271749-hqv3lb4u0q82t68vperem5oi3kosskol.apps.googleusercontent.com';
  var developerKey = 'AIzaSyBTEWsJ4aXdoOzB4ey81eX9-ja7HejL4Qc';
  var accessToken;
  function onApiLoad() {
    gapi.load('auth', authenticateWithGoogle);
    gapi.load('picker');
  }
  function authenticateWithGoogle() {
    window.gapi.auth.authorize({
      'client_id': clientId,
      'scope': ['https://www.googleapis.com/auth/drive']
    }, handleAuthentication);
  }
  function handleAuthentication(result) {
    if(result && !result.error) {
      accessToken = result.access_token;
      setupPicker();
    }
  }
  function setupPicker() {
    var picker = new google.picker.PickerBuilder()
      .setOAuthToken(accessToken)
      .setDeveloperKey(developerKey)
      .addView(new google.picker.DocsUploadView())
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .setCallback(myCallback)
      .build();
    picker.setVisible(true);
  }
  function myCallback(data) {
    if (data.action == google.picker.Action.PICKED) {
      alert(data.docs[0].name);
    } else if (data.action == google.picker.Action.CANCEL) {
      alert('goodbye');
    }
  }
</script>
<script src="https://apis.google.com/js/api.js?onload=onApiLoad"></script>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      